export interface Search {
    actionButtonsColor?:        ActionButtonsColor;
    authorizedActions?:         AuthorizedActions;
    cardLinks?:                 CardLinks;
    elementId?:                 ElementID;
    functionalFieldsToDisplay?: FunctionalFieldsToDisplay;
    listViewFieldsToDisplay?:   ListViewFieldsToDisplay;
    sort?:                      Sort;
    technicalFieldsToDisplay?:  FunctionalFieldsToDisplay;
    templates?:                 TemplateSchema[];
    templatesFooter?:           TemplateSchema[];
    visualAlerts?:              VisualAlertsSchema[];
}

export interface ActionButtonsColor {
    borderRadius?: string;
    colors?:       Colors;
    hoverColors?:  HoverColors;
}

export interface Colors {
    backgroundColor?: string;
    borderColor?:     string;
    textColor?:       string;
}

export interface HoverColors {
    backgroundColor?: string;
    borderColor?:     string;
    textColor?:       string;
}

export interface AuthorizedActions {
    attachments?: boolean;
}

export interface CardLinks {
    attachments?: boolean;
    mediaLinks?:  boolean;
}

export interface ElementID {
    extension?:  boolean;
    identifier?: string;
}

export interface FunctionalFieldsToDisplay {
    default?:       string[];
    metamodel?:     Metamodel[];
    metamodelType?: MetamodelTypeObject[];
}

export interface Metamodel {
    fields:      string[];
    metamodelId: number[];
}

export interface MetamodelTypeObject {
    fields:        string[];
    metamodelType: MetamodelTypeEnum[];
}

export enum MetamodelTypeEnum {
    Document = "DOCUMENT",
    Other = "OTHER",
    Picture = "PICTURE",
    Sound = "SOUND",
    Video = "VIDEO",
}

export interface ListViewFieldsToDisplay {
    default?: string[];
}

export interface Sort {
    fields?: Field[];
}

export interface Field {
    default?:  boolean;
    direction: Direction;
    field:     string;
}

export enum Direction {
    Asc = "ASC",
    Desc = "DESC",
}

export interface TemplateSchema {
    animation?:            Animation;
    autoTopMargin?:        boolean;
    backgroundColor?:      string;
    classNames?:           string;
    columns?:              Column[];
    columnSpacing?:        number;
    container?:            boolean;
    gridSpacing?:          number;
    isCollapsed?:          boolean;
    isFinalized?:          boolean;
    itemTitle?:            string;
    margin?:               number;
    padding?:              number;
    rowSpacing?:           number;
    verticallyExtendable?: boolean;
}

export interface Animation {
    animationType?: AnimationType;
}

export enum AnimationType {
    FadeInBottom = "FADE_IN_BOTTOM",
    NoAnimation = "NO_ANIMATION",
}

export interface Column {
    backgroundColor?: string;
    borderColor?:     string;
    borderWidth?:     number;
    breakpoints?:     Breakpoint;
    classNames?:      string;
    itemTitle?:       string;
    widgets?:         WidgetSchema[];
}

export interface Breakpoint {
    lg?: number;
    md?: number;
    sm?: number;
    xl?: number;
    xs?: number;
}

export interface WidgetSchema {
    classNames?: string;
    id:          string;
    type:        Type;
}

export enum Type {
    Associations = "associations",
    Attachments = "attachments",
    Baskets = "baskets",
    Custom = "custom",
    Edito = "edito",
    Footer = "footer",
    Header = "header",
    Image = "image",
    Menu = "menu",
    Mosaic = "mosaic",
    News = "news",
    Report = "report",
    Search = "search",
    SectionTitle = "sectionTitle",
    Sheet = "sheet",
    Spacer = "spacer",
    Tabs = "tabs",
    Video = "video",
}

export interface VisualAlertsSchema {
    backgroundColor?:   string;
    borderRadius?:      number;
    color?:             string;
    dateTargetMode:     DateTargetMode;
    dateTargetNumber:   number;
    dateTargetUnit:     DateTargetUnit;
    description?:       string;
    displayFieldValue?: boolean;
    fieldInternalName:  string;
    fieldType:          FieldType;
    fontSize?:          number;
    marginBottom?:      number;
    marginLeft?:        number;
    marginRight?:       number;
    marginTop?:         number;
    padding?:           number;
    position:           Position;
    subStatusId?:       number;
    text:               TranslationSchema[];
}

export enum DateTargetMode {
    ExpireWithin = "EXPIRE_WITHIN",
    ExpiredSince = "EXPIRED_SINCE",
    ExpiredSinceLessThan = "EXPIRED_SINCE_LESS_THAN",
}

export enum DateTargetUnit {
    Days = "DAYS",
    Months = "MONTHS",
    Years = "YEARS",
}

export enum FieldType {
    Date = "DATE",
    SubStatus = "SUB_STATUS",
}

export enum Position {
    BottomLeft = "BOTTOM_LEFT",
    BottomRight = "BOTTOM_RIGHT",
    TopLeft = "TOP_LEFT",
    TopRight = "TOP_RIGHT",
}

export interface TranslationSchema {
    empty?:      boolean;
    language:    Language;
    ressources?: any;
    value?:      string;
}

export enum Language {
    De = "de",
    En = "en",
    Es = "es",
    Fr = "fr",
    Hu = "hu",
    It = "it",
    Nl = "nl",
    Pl = "pl",
    Pt = "pt",
    Ro = "ro",
}
